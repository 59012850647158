<script>
import DisplayMixin from '@/mixins/DisplayMixin';

import Icon from '@/components/atoms/Icon';

export default defineNuxtComponent({
  name: 'SortMenu',
  components: { Icon },
  mixins: [DisplayMixin],
  emits: ['onSortCriteriaChanged'],
  props: {
    sortCriteria: {
      type: Array,
      required: true,
    },
  },
  methods: {
    select(current) {
      this.$emit('onSortCriteriaChanged', current);
    },
    optionName(item) {
      return this.getDisplayMode === 'tablet' ? item.description : item.name;
    },
  },
});
</script>

<template>
  <div
    class="absolute z-20 mt-18 flex flex-col items-start rounded bg-neutral-2-50 px-6 pb-6 shadow-xl dark:bg-dark-secondary"
  >
    <div
      v-for="(item, index) in sortCriteria"
      :key="index"
      :class="{ 'font-medium text-primary': item.checked }"
      class="flex w-full cursor-pointer items-center pt-6"
      @click="select(item)"
    >
      <trailblazer-icon :name="item.icon" class="mr-4" />
      {{ optionName(item) }}
      <div class="ml-auto">
        <Icon
          v-if="getDisplayMode === 'tablet'"
          :size="20"
          :name="item.checked ? 'radioButtonChecked' : 'radioButtonDefault'"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.sortBarScrollbar .ps {
  @apply max-h-64 w-full;
}
</style>
