<script>
import { mapState } from 'vuex';

import SortMenu from './SortMenu';
import DisplayMixin from '@/mixins/DisplayMixin';
import MobileDropdownControllerMixin from '~/mixins/MobileDropdownControllerMixin';

import Icon from '@/components/atoms/Icon';

export default defineNuxtComponent({
  components: { Icon, SortMenu },
  mixins: [MobileDropdownControllerMixin, DisplayMixin],
  emits: ['sortCriteriaChanged', 'onClearFilters'],
  props: {
    label: {
      type: String,
      default: 'Sort',
      required: false,
    },
    sortCriteria: {
      type: Array,
      default: () => [],
      required: false,
    },
    module: {
      type: String,
      default: 'tickets',
      required: false,
    },
  },
  data() {
    return {
      ascendingOrder: true,
      selection: '',
    };
  },
  computed: {
    ...mapState('ContextStore', {
      color: state => state.accentColor,
      darkMode: state => state.darkMode,
    }),
  },
  mounted() {
    this.closeSortMenu();
  },
  methods: {
    sortCriteriaChanged(criteria) {
      if (!criteria.name)
        return;

      let sortParameter = this.ascendingOrder ? '-' : '';

      sortParameter = sortParameter + criteria.value;
      this.selection = criteria.name;
      this.$emit('sortCriteriaChanged', sortParameter, criteria);
      this.closeSortMenu();
    },
    toggleSortOrder() {
      this.ascendingOrder = !this.ascendingOrder;
      const checkedCriteria = this.sortCriteria.find(
        criteria => criteria.checked,
      );
      if (!checkedCriteria)
        return;

      this.sortCriteriaChanged(checkedCriteria);
    },
    toggleSortMenu() {
      if (this.isDropdownOpen(`sortMenu${this.label}`))
        this.closeSortMenu();
      else
        this.openMobileDropdown();
    },
    closeSortMenu() {
      this.setDropdownOpened(false);
      this.setOpenModalCurrentContext('');
    },
    openMobileDropdown() {
      this.setDropdownOpened(true);
      this.setDropdownOptions(this.sortCriteria);
      this.setContextData({ sortOrder: this.ascendingOrder });
      this.setDropdownLabel(`Sort ${this.module} by:`);
      this.setOpenModalCurrentContext(`sortMenu${this.label}`);
      this.setDropdownModule(this.module);
      this.setDropdownType('sortingDropdown');
      this.setOnOptionChangedCallback((changedOption) => {
        this.sortCriteriaChanged(changedOption);
        this.setDropdownOpened(false);
      });

      this.registerCustomEvent({
        name: 'onToggleOrder',
        callback: () => {
          this.toggleSortOrder();
          this.setDropdownOpened(false);
        },
      });
      this.registerCustomEvent({
        name: 'clearFilters',
        callback: () => {
          this.onClearFilters();
          this.setDropdownOpened(false);
        },
      });
    },
    onClearFilters() {
      this.$emit('onClearFilters');
    },
  },
});
</script>

<template>
  <div
    class="flex cursor-pointer rounded border-2 border-transparent bg-white shadow-sm"
    :class="{ 'border-primary': isDropdownOpen(`sortMenu${label}`) }"
  >
    <div
      class="flex items-center border-r-2 border-solid border-neutral-2-200 p-4 dark:border-menu-item-background"
      @click="toggleSortOrder()"
    >
      <Icon
        :class="{ 'rotate-180': !ascendingOrder }"
        :accent="color"
        name="sort"
        :dark-mode="darkMode"
      />
    </div>
    <div
      class="flex flex-1 items-center justify-center px-6 py-4 font-medium"
      :class="{
        'text-primary': isDropdownOpen(`sortMenu${label}`) || selection !== '',
      }"
      @click="toggleSortMenu"
    >
      {{ selection === '' ? label : selection }}
    </div>

    <SortMenu
      v-show="isDropdownOpen(`sortMenu${label}`)"
      class="z-10"
      :class="{
        'block w-38': getDisplayMode.includes('desktop'),
        'block w-72': getDisplayMode === 'tablet',
        'hidden': getDisplayMode === 'mobile',
      }"
      :sort-criteria="sortCriteria"
      @on-sort-criteria-changed="sortCriteriaChanged"
    />
  </div>
</template>
